.image{
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 400px;
}
.slide{
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px rgb(101, 101, 118);
    width: 100%;
    height: 100%;
}
@media (max-width: 768px) {
   .image {
        width: 100%;
    }
}
.slide-hidden{
    display: none;
}
.arrow{
    position: absolute;
    width: 2rem;
    height:2rem;
    color:white;
    filter:drop-shadow(0px 0 px 5px #555);
}
.arrow:hover{
    cursor: pointer;
    color: rgb(92, 121, 121);
}
.arrow-left{
    left: 1rem;
}
.arrow-right{
    right:1rem
}
.indicators{
    display: flex;
    position: absolute;
    bottom: 1rem;
}
.indicator{
    background-color: bisque;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    border: none;
    outline:none;
    box-shadow: 0px 0px 5px #555;
    margin:  0.2rem;
    cursor: pointer;
    
}
.indicator-inactive{
    background-color: #555;
}